<template>
  <StaticPagePug>
    <div id="resetPassword">
      <div class="form-wrapper">
        <div class="inner">
          <div class="title">{{ $t('resetPassword.header') }}</div>
          <div class="resetPassword-box" v-show="isAuthorized">
            <div class="from-box" v-show="formFlag">
              <el-form
                label-position="top"
                :model="ruleResetPasswordForm"
                :rules="rulesResetPassword"
                ref="ruleResetPasswordForm"
              >
                <el-form-item :label="$t('common.field.newPw')" prop="pass">
                  <el-input :type="passwordVisible ? 'text' : 'password'" v-model="ruleResetPasswordForm.pass" autocomplete="off" class="suffix-input" :placeholder="$t('common.field.newPw')">
                    <template slot="suffix">
                      <img src="@/assets/images/pug/eye-line.png" alt="view" @click="togglePasswordVisibility" />
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item :label="$t('common.field.confirmPw')" prop="checkPass">
                  <el-input :type="verifyPasswordVisible ? 'text' : 'password'" v-model="ruleResetPasswordForm.checkPass" autocomplete="off" class="suffix-input" :placeholder="$t('common.field.newPw')">
                    <template slot="suffix">
                      <img src="@/assets/images/pug/eye-line.png" alt="view" @click="toggleVerifyPasswordVisibility" />
                    </template>
                  </el-input>
                </el-form-item>
                <p>
                  {{ $t('common.formValidation.newPwAccFormat') }}
                </p>
                <el-form-item class="btn-item">
                  <el-button
                    class="ok-btn"
                    type="primary"
                    @click.native="submitForm('ruleResetPasswordForm')"
                    data-testid="submit"
                  >
                    {{ $t('common.button.submit') }}
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="reset-success" v-show="successFlag">
              <img src="@/assets/images/pug/success.png" alt />
              <p class="success_info">{{ $t('resetPassword.succ') }}</p>
              <div class="btn-box">
                <router-link :to="{ name: 'home' }" class="el-button ok-btn">
                  {{ $t('common.button.bkToHm') }}
                </router-link>
              </div>
            </div>
          </div>
          <div class="resetPassword-box" v-show="isNotAuthroized">
            <p class="no-authroization">{{ $t('resetPassword.failed') }}</p>
          </div>
        </div>
      </div>
    </div>
    <LimitWithdrawDialog
      :visible.sync="visible"
      :day="day"
      @changeVisible="changeVisible"
      @confirmPw="confirmPw"
    ></LimitWithdrawDialog>
  </StaticPagePug>
</template>

<script>
import StaticPagePug from '@/components/template/staticPage/StaticPagePug';
import { rsa } from '@/util/encrypt';
import { apiCheckResetPasswordAuthorization, apiSubmitResetMt4AccountPassword, apiGetSecuritysRule } from '@/resource';
import LimitWithdrawDialog from '@/components/dialog/withdraw.vue';
import withdrawMixin from '@/mixins/withdrawl/withdrawLimit';

export default {
  name: 'resetPassword',
  mixins: [withdrawMixin],

  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else {
        const reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,16}$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t('common.formValidation.newPwReqErr')));
        }
      }
      callback();
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else if (value !== this.ruleResetPasswordForm.pass) {
        this.isError = true;
        callback(new Error(this.$t('common.formValidation.confirmNotMatch')));
      }
      this.isError = false;
      callback();
    };
    return {
      day: '',
      visible: false,
      isAuthorized: false,
      isNotAuthroized: true,
      formFlag: true,
      successFlag: false,
      isError: false,
      ruleResetPasswordForm: {
        pass: '',
        checkPass: '',
        token: ''
      },
      rulesResetPassword: {
        pass: [{ validator: validatePass, trigger: ['blur', 'change'] }],
        checkPass: [{ validator: validatePass2, trigger: ['blur', 'change'] }]
      }
    };
  },
  components: { StaticPagePug, LimitWithdrawDialog },
  mounted() {
    const token = this.$route.query.token;
    this.verifyAuthorization(token);
  },
  methods: {
    confirmPw() {
      apiSubmitResetMt4AccountPassword({
        token: this.ruleResetPasswordForm.token,
        newPassword: rsa(this.ruleResetPasswordForm.pass)
      })
        .then(resp => {
          if (resp.data.code == 0 && resp.data.data == true) {
            this.formFlag = false;
            this.successFlag = true;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    changeVisible(val) {
      this.visible = val;
    },
    verifyAuthorization(token) {
      apiCheckResetPasswordAuthorization({
        token: token
      }).then(resp => {
        if (resp.data.code == 0) {
          this.ruleResetPasswordForm.token = resp.data.data;
          this.isAuthorized = true;
          this.isNotAuthroized = !this.isAuthorized;
        } else {
          this.isAuthorized = false;
          this.$router.push('/login');
        }
      });
    },

    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          await this.getSecuritysRule();
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/components/loginForm.scss';
@import '@/assets/css/pages/resetProfilePasswordPug.scss';
</style>
